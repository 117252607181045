import { useState, useEffect } from 'react';
import ColumnDefinition from '../../../../../pieces/grids/columnGenerator';
import * as allConstants from '../../../../../constants/allConstants';
import {INSPECTION_REQUIREMENTS} from '../../../../../constants/options/filterOptions/columnFilterOptions';
import Utils from '../../../../../utils/utils';
import {getStatusColumns} from '../../../helpers/jobGridOptions';
//import RequirementFilter from '../../../../../pieces/grids/requirementFilter';

const inspectionApiTypes = {
  all: 'all',
  previous: 'previous',
  pending: 'pending',
  manual: 'manual',
  completed: 'completed',
  hold: 'hold',
  failed: 'failed',
  report: 'report',
};


const getInspectionColumns = (handleDisplayJobDetails, cellRendererName, jobOptions, tagNameList = []) => {
  const JOB_FIELDS = allConstants.JOB_FIELDS;
  const INSP_FIELDS = allConstants.INSPECTION_FIELDS;
  const SCHEDULING_FIELDS = allConstants.SCHEDULING_FIELDS;

  return {
    id: new ColumnDefinition({field: JOB_FIELDS.ID, hide: true}),
    company: new ColumnDefinition({
      field: allConstants.COMPANY_FIELDS.ID,
      parent: 'company',
      hide: true,
    }),
    companyName: new ColumnDefinition({
      field: allConstants.COMPANY_FIELDS.NAME,
      sortable: false,
      parent: 'company',
      hide: false
    }),
    jobNumber: new ColumnDefinition({
      field: JOB_FIELDS.JOB_NUMBER,
      sortable: true,
      lockPinned: true,
      filter: 'agNumberColumnFilter',
      pinned: 'left',
      checkboxSelection: true,
      cellRenderer: cellRendererName,
      cellRendererParams: (params) => {
        return {
          handleExpand: handleDisplayJobDetails,
          showInspectionResults: true,
        };
      },
    }),
    jobNumberWithOutResults: new ColumnDefinition({
      field: JOB_FIELDS.JOB_NUMBER,
      sortable: false,
      lockPinned: true,
      filter: 'agNumberColumnFilter',
      pinned: 'left',
      checkboxSelection: true,
      cellRenderer: cellRendererName,
      cellRendererParams: (params) => {
        return {
          handleExpand: handleDisplayJobDetails,
          showInspectionResults: false,
        };
      },
    }),
    duplicateOf: new ColumnDefinition ({
      field: JOB_FIELDS.DUPLICATE_OF,
      sortable: true,
      filter: 'agNumberColumnFilter',
      valueGetter: (({data}) => data?.[JOB_FIELDS.DUPLICATE_OF.api_name]?.[JOB_FIELDS.JOB_NUMBER.api_name])
    }),
    jobType: new ColumnDefinition({
      field: JOB_FIELDS.JOB_TYPE,
      sortable: true,
      filter: 'agSetColumnFilter',
      filterValues: Object.values(allConstants.JOB_TYPES).map((s) => s.value).filter((s) => s !== allConstants.JOB_TYPES.COMMERCIAL.value),
    }),
    hoName: new ColumnDefinition({
      field: JOB_FIELDS.GENERAL.HO_NAME,
      sortable: false,
      parent: JOB_FIELDS.GENERAL.HOMEOWNER.api_name,
      valueGetter: (({data})=> data?.[JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[JOB_FIELDS.GENERAL.HO_NAME.api_name] || 'N/A')
    }),
    address: new ColumnDefinition({
      field: JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_LINE_1,
      headerName: JOB_FIELDS.GENERAL.ADDRESS.label,
      parent: JOB_FIELDS.GENERAL.ADDRESS.api_name,
      valueGetter: (({data}) => {

        return `${data?.[JOB_FIELDS.GENERAL.ADDRESS.api_name]?.line1??""} ${data?.[JOB_FIELDS.GENERAL.ADDRESS.api_name]?.city??""},
               ${data?.[JOB_FIELDS.GENERAL.ADDRESS.api_name]?.state??""}  ${data?.[JOB_FIELDS.GENERAL.ADDRESS.api_name]?.zipCode??""}`;
      }),
    }),
    hoPhone: new ColumnDefinition({
      field: JOB_FIELDS.GENERAL.HO_PHONE,
      sortable: false,
      parent: JOB_FIELDS.GENERAL.HOMEOWNER.api_name,
      valueGetter: (({data})=> data?.[JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[JOB_FIELDS.GENERAL.HO_PHONE.api_name] || 'N/A')
    }),
    assignedCity: new ColumnDefinition({
      parent: allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name,
      field: allConstants.CITY_FIELDS.NAME,
      sortable: false,
      headerName: 'assigned city'
    }),

    cityType: new ColumnDefinition({
      parent: allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name,
      field: allConstants.CITY_FIELDS.CITY_TYPE,
      sortable: false,
      headerName: 'city type',
      wrapText: true,
      cellStyle: {
        lineHeight: '14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      },
      removeDefaultFilter: true,
    }),
    inspectionStatus: new ColumnDefinition({
      field: allConstants.INSPECTION_FIELDS.INSPECTION_STATUS,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      headerName: 'inspection status',
      cellRenderer: 'statusRenderer',
      filter: 'agSetColumnFilter',
      filterValues: Object.values(allConstants.INSPECTION_STATUSES).map((s) => s.value),
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return inspectionData?.[INSP_FIELDS.INSPECTION_STATUS.api_name];
        }
        return allConstants.PERMIT_STATUSES.NOT_REQUIRED.value;
      }),
    }),
    // Thanh 02/02/24
    schedAssignee: new ColumnDefinition({
      field: allConstants.INSPECTION_FIELDS.SCHED_ASSIGNEE,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      //headerName: 'Sched Assignee',
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return inspectionData?.[INSP_FIELDS.SCHED_ASSIGNEE.api_name];
        }
        return null;
      })
    }),
    followUpAssignee: new ColumnDefinition({
      field: allConstants.INSPECTION_FIELDS.FOLLOW_UP_ASSIGNEE,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      //headerName: 'FU Assignee',
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return inspectionData?.[INSP_FIELDS.FOLLOW_UP_ASSIGNEE.api_name];
        }
        return null;
      })
    }), 
    tags: new ColumnDefinition({
      field: allConstants.INSPECTION_FIELDS.TAGS,
      sortable: true,
      parent: INSP_FIELDS.INSPECTION.api_name,
      headerName: 'Tags',
      //wrapText: true,
      cellStyle: {
        lineHeight: '14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      },
      filter: 'agSetColumnFilter',
      filterValues: tagNameList,
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          const tags = inspectionData?.[INSP_FIELDS.TAGS.api_name];
  
          if (tags !== undefined && tags.length > 0) {
            const tagNames = tags.map(x => x.tagName);
            return {tagNames, showTag: true};
          }
        }
        return null;
      }),
      cellRenderer: 'LineBreakCellRenderer',
    }),
    tagsReminderDate: new ColumnDefinition({
      field: allConstants.INSPECTION_FIELDS.TAGS_REMINDER_DATE,
      sortable: true,
      parent: INSP_FIELDS.INSPECTION.api_name,
      headerName: 'Reminder',
      cellStyle: {
        lineHeight: '14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      },
      filter: 'agDateColumnFilter',
      filterValues: tagNameList,
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          const tags = inspectionData?.[INSP_FIELDS.TAGS.api_name];
  
          if (tags !== undefined && tags.length > 0) {
            const tagReminderDates = tags.map(x => Utils.formatIsoDateString(x.reminderDate, {date: true}));
            return {tagNames: tagReminderDates, showTag: false};
          }
        }
        return null;
      }),
      cellRenderer: 'LineBreakCellRenderer',
    }),
    inspectionStatusChangeDate: new ColumnDefinition({
      field: allConstants.INSPECTION_FIELDS.INSPECTION_STATUS_CHANGE_DATE,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      headerName: 'Status Changed',
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return Utils.formatIsoDateString(inspectionData?.[INSP_FIELDS.INSPECTION_STATUS_CHANGE_DATE.api_name], {date: true});
        }
        return null;
      })
    }),
    inspectionScheduledDate: new ColumnDefinition({
      field: allConstants.JOB_FIELDS.HERS_SCHEDULED_DATE,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      headerName: 'scheduled date',
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          //console.log('inspection');
          //console.log(data);

          //if (inspectionData?.[INSP_FIELDS.INSPECTION_STATUS.api_name] === "Completed") {
          //  return Utils.formatIsoDateString(inspectionData?.[SCHEDULING_FIELDS.LAST_SCHED_DATE.api_name], {date: true});            
          //}
          //else {
            return Utils.formatIsoDateString(inspectionData?.[SCHEDULING_FIELDS.START_DATE.api_name], {date: true});            
          //}
        }
        return null;

      })
    }),
    inspectionResult: new ColumnDefinition({
      field: INSP_FIELDS.RESULT,
      sortable: false,
      parent: `${INSP_FIELDS.INSPECTION.api_name}.${INSP_FIELDS.INSPECTION_RESULTS.api_name}`,
      headerName: 'result',
      cellRenderer: 'statusRenderer',
      filter: 'agSetColumnFilter',
      width: 120,
      filterValues: Object.values(allConstants.RESULT_OPTIONS).map((s) => s.value),
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          const tests = inspectionData?.[INSP_FIELDS.INSPECTION_RESULTS.api_name];
          if (!tests || tests.length === 0) {
            return null;
          }
          const lastTest = tests[tests.length - 1];
          return lastTest[INSP_FIELDS.RESULT.api_name];
        }
        return null;

      })
    }),
    lastHoCallDate: new ColumnDefinition({
      field: allConstants.CALL_LOGS_FIELDS.LAST_HO_CALL_DATE,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return Utils.formatIsoDateString(inspectionData?.[allConstants.CALL_LOGS_FIELDS.LAST_HO_CALL_DATE.api_name], {date: true});
        }
        return null;
      })
    }),

    // lastHoCallDate2: new ColumnDefinition({
    //   field: allConstants.CALL_LOGS_FIELDS.LAST_HO_CALL_DATE,
    //   sortable: false,
    //   parent: INSP_FIELDS.INSPECTION.api_name,
    //   filter: 'agDateColumnFilter',
    //   valueGetter: (({data}) => {
    //     const callsData = data?.[INSP_FIELDS.INSPECTION.api_name]?.[allConstants.CALL_LOGS_FIELDS.CALL_LOG.api_name];
    //     if(callsData && callsData.length > 0) {
    //       //filter calls to home owner
    //       const callsToHomeOwner = callsData.filter(call => call[allConstants.CALL_LOGS_FIELDS.TYPE.api_name] === allConstants.CALL_TO_OPTIONS.HOME_OWNER.value);
      

    //       if (callsToHomeOwner.length > 0) {
    //         const callToHO = callsToHomeOwner[callsToHomeOwner.length-1];
    //         return Utils.formatIsoDateString(callToHO[allConstants.CALL_LOGS_FIELDS.DATE.api_name], {date: true});
    //       }
    //       return  '';
    //     }
    //     return '';
    //   }
    //   ),
    // }),
    lastCallDate: new ColumnDefinition({
      field: allConstants.CALL_LOGS_FIELDS.LAST_CALL_DATE,
      parent: INSP_FIELDS.INSPECTION.api_name,
      filter: 'agNumberColumnFilter',
      valueGetter: (({data}) => {
        const callsData = data?.[INSP_FIELDS.INSPECTION.api_name]?.[allConstants.CALL_LOGS_FIELDS.CALL_LOG.api_name];
        if(callsData && callsData.length > 0) {
          const callTo = callsData[callsData.length-1];
          return Utils.formatIsoDateString(callTo[allConstants.CALL_LOGS_FIELDS.DATE.api_name], {date: true});
        }
        return '';
      }
      ),
    }),
    // callsCount: new ColumnDefinition({
    //   field: allConstants.CALL_LOGS_FIELDS.NUMBER_OF_CALLS,
    //   sortable: false,
    //   parent: INSP_FIELDS.INSPECTION.api_name,
    //   filter: 'agNumberColumnFilter',
    //   filterParams: numberFilterParams,
    //   valueGetter: (({data}) => {
    //     const callsData = data?.[INSP_FIELDS.INSPECTION.api_name]?.[allConstants.CALL_LOGS_FIELDS.CALL_LOG.api_name];
    //     if(callsData && callsData.length > 0) {
    //       //filter calls to home owner
    //       const callsToHomeOwner = callsData.filter(call => call[allConstants.CALL_LOGS_FIELDS.TYPE.api_name] === allConstants.CALL_TO_OPTIONS.HOME_OWNER.value);
    //       return callsToHomeOwner.length ?? '';
    //     }
    //     return '';
    //   }
    //   ),
    // }),
    callHoCount: new ColumnDefinition({
      field: allConstants.CALL_LOGS_FIELDS.CALL_HO_COUNT,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      //filter: 'numberRangeFilter',
      filter: 'agNumberColumnFilter',
      //filterParams: numberRangeFilter,
    }),
    // needsLadder: new ColumnDefinition({
    //   field: INSP_FIELDS.LADDER,
    //   sortable: false,
    //   parent: INSP_FIELDS.INSPECTION.api_name,
    //   //filter: 'numberRangeFilter',
    //   filter: 'agBooleanColumnFilter',
    //   //filterParams: numberRangeFilter,
    //   cellRenderer: 'booleanCellRenderer'
    // }),
    requirements: new ColumnDefinition({
      field: INSP_FIELDS.REQUIREMENTS,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      filter: 'requirementFilter',
      //filter: 'agSetColumnFilter',
      filterValues: Object.values(INSPECTION_REQUIREMENTS).map((s) => s.value),
      //filterParams: ["Standard"],
      //filterFramework: RequirementFilter,
      valueGetter: (({data}) => {
        const inspectionData = data?.[INSP_FIELDS.INSPECTION.api_name];
        if (inspectionData) {
          return {
            [INSP_FIELDS.LADDER.api_name]: inspectionData[INSP_FIELDS.LADDER.api_name],
            [INSP_FIELDS.REQUIREMENTS.api_name]: inspectionData?.[INSP_FIELDS.REQUIREMENTS.api_name],
          };
        }
        return null;
      }),

      cellRenderer: 'InspectionRequirementsRenderer',

    }),
    createdTime: new ColumnDefinition({
      field: JOB_FIELDS.CREATE_TIME,
      sortable: false,
      parent: INSP_FIELDS.INSPECTION.api_name,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => Utils.formatIsoDateString(data?.[INSP_FIELDS.INSPECTION.api_name]?.[JOB_FIELDS.CREATE_TIME.api_name],{date: true}))
    }),
    ...getStatusColumns(jobOptions)
  };
};

export {getInspectionColumns, inspectionApiTypes};
