import ColumnDefinition from '../../../pieces/grids/columnGenerator';
import * as allConstants from '../../../constants/allConstants';
import Utils from '../../../utils/utils';
import jobFields from '../../../constants/formFields/jobFields';
//import {PERMIT_MODULE_FIELDS} from '../../../constants/allConstants';
import HyperlinkCellRenderer from '../../../pieces/grids/hyperlinkCellRenderer';

const jobApiTypes = {
  hers: 'hers',
  permits: 'permits',
  all: 'all',
  euc: 'euc',
  drafts: 'drafts',
  inspections: 'inspections',
  cf1r: 'cf1r',
  report: 'report',
};

const getStatusColumns = (jobOptions)=> {
  const JOB_FIELDS = allConstants.JOB_FIELDS;
  return {

    permitStatus: new ColumnDefinition({
      field: JOB_FIELDS.PERMIT_STATUS, cellRenderer: 'statusRenderer',
      filter: 'agSetColumnFilter',
      filterValues: Object.values(jobOptions?.permitStatuses ? jobOptions.permitStatuses : allConstants.PERMIT_STATUSES).map((s) => s.value)
    }),
    permitStatusChangeDate: new ColumnDefinition({
      field: JOB_FIELDS.PERMIT_STATUS_CHANGE_DATE,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => Utils.formatIsoDateString(data[JOB_FIELDS.PERMIT_STATUS_CHANGE_DATE.api_name], {date: true}))
    }),
    permitAssignee: new ColumnDefinition({
      field: JOB_FIELDS.PERMIT_ASSIGNEE,
    }),
    hersStatus: new ColumnDefinition({
      field: JOB_FIELDS.HERS_STATUS, cellRenderer: 'statusRenderer',
      filter: 'agSetColumnFilter',
      valueGetter: (({data}) => {
        const val = data?.[JOB_FIELDS.HERS_STATUS.api_name];
        if (val) return val;
        return allConstants.PERMIT_STATUSES.NOT_REQUIRED.value;
      }),
      filterValues: Object.values(jobOptions?.hersStatuses ? jobOptions?.hersStatuses : allConstants.HERS_STATUSES).map((s) => s.value).filter((v) => v !== allConstants.HERS_STATUSES.RESCHEDULED.value)
    }),
    hersStatusChangeDate: new ColumnDefinition({
      field: JOB_FIELDS.HERS_STATUS_CHANGE_DATE,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        const val = data?.[JOB_FIELDS.HERS_STATUS_CHANGE_DATE.api_name];
        if (val) return Utils.formatIsoDateString(val, {date: true});
        return '';
      }),

    }),
    mailingStatus: new ColumnDefinition({field: JOB_FIELDS.MAILING_STATUS,  cellRenderer: 'statusRenderer',
      filter:'agSetColumnFilter',
      filterValues: Object.values(jobOptions?.mailingStatuses ? jobOptions.mailingStatuses : allConstants.MAILING_STATUSES).map((s)=> s.value)
    }),
    mailingStatusChangeDate: new ColumnDefinition({
      field: {...JOB_FIELDS.MAILING_STATUS_CHANGE_DATE, ...{label: 'mailing change date'}},
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => {
        const val = data?.[JOB_FIELDS.MAILING_STATUS_CHANGE_DATE.api_name];
        if (val) return Utils.formatIsoDateString(val, {date: true});
        return '';
      }),

    }),
  };
};

const getJobColumns = (handleDisplayJobDetails, cellRendererName, jobOptions, userCompanies = []) => {

  const JOB_FIELDS = allConstants.JOB_FIELDS;

  return {
    id: new ColumnDefinition({field: JOB_FIELDS.ID, hide: true}),
    company: new ColumnDefinition({field: allConstants.COMPANY_FIELDS.ID,
      parent: 'company',
      hide: true
    }),
    companyName: new ColumnDefinition({field: allConstants.COMPANY_FIELDS.NAME,
      parent: 'company',
      hide: false
    }),
    companyNameByUser: new ColumnDefinition({
      field: allConstants.COMPANY_FIELDS.NAME,
      parent: 'company',
      hide: false,
      filter: 'agSetColumnFilter',
      filterValues: userCompanies,
      // valueGetter: (({data}) => {
      //   const userCompanyData = data?.[JOB_FIELDS.INSPECTION.api_name];
      //   if (inspectionData) {
      //     const tags = inspectionData?.[INSP_FIELDS.TAGS.api_name];
  
      //     if (tags !== undefined && tags.length > 0) {
      //       const tagNames = tags.map(x => x.tagName);
      //       return {tagNames, showTag: true};
      //     }
      //   }
      //   return null;
      // }),
    }),    
    jobNumber: new ColumnDefinition({
      field: JOB_FIELDS.JOB_NUMBER,
      lockPinned: true,
      filter: 'agNumberColumnFilter',
      pinned: 'left',
      checkboxSelection: true,
      cellRenderer: cellRendererName,
      cellRendererParams: (params)=> {
        return {
          handleExpand: handleDisplayJobDetails,
          showWarning: params?.data?.[JOB_FIELDS.IS_DRAFT.api_name],
        };},
    }),
    // serviceTitanId: new ColumnDefinition( {
    //   field: JOB_FIELDS.SERVICE_TITAN_ID,
    //   headerName: 'Srv Titan ID',
    // }), 
    serviceTitanJobNumber: new ColumnDefinition( {
      field: JOB_FIELDS.SERVICE_TITAN_JOB_NUMBER,
      headerNmae: 'Srv Titan Job #',
      //cellRenderer: 'hyperlinkCellRenderer',
      cellRenderer: 'hyperlinkCellRenderer',
      cellRendererParams: (params) => {
        let stUrl = '/#/Job/Index/' + params?.data?.[JOB_FIELDS.SERVICE_TITAN_ID.api_name];

        if (params?.data?.company.id == '651de7063872cc62b79a9902') {
            stUrl = 'https://integration.servicetitan.com' + stUrl;
        }
        else {
          stUrl = 'https://go.servicetitan.com' + stUrl;
        }
        return {
          value: params?.data?.[JOB_FIELDS.SERVICE_TITAN_JOB_NUMBER.api_name],
          link: stUrl
        };
      },
      // valueGetter: (({data}) => {

      //   const val = data?.[JOB_FIELDS.SERVICE_TITAN_JOB_NUMBER.api_name];
      //   if (val) return { value: val, link: 'http://msn.com' }
      //   return { value: '', link: ''};
      // }),
    }),
    // Thanh 02/04/24
    isServiceTitan: new ColumnDefinition( {
      field: JOB_FIELDS.IS_SERVICE_TITAN,
      headerName: 'Srv Titan?',
      filter: "agSetColumnFilter",
      filterValues: ['Yes', 'No'],
      cellRenderer: params => params.value ? "Yes" : "No",     
    }), 
    notSyncedToCRM: new ColumnDefinition({
      field: {api_name: 'zohoId', label: ''},
      headerName: 'Not synced to CRM',
      cellStyle: {color: 'red'},
      valueGetter: (({data})=> {
        const jobZohoId = data.zohoId;
        const notes = data.notes;
        const docs = data.attachments;
        const internalNotes = data.internalNotes;
        let value = '';
        if(!jobZohoId) value = value + 'Job';
        if(notes && notes.filter((n)=> !n.zohoId).length !== 0) {
          value = value + ' N';
        }
        if(docs && docs.filter((n)=> !n.zohoId).length !== 0) {
          value = value + ' D';
        }
        if(internalNotes && internalNotes.filter((n)=> !n.zohoId).length !== 0) {
          value = value + ' I';
        }

        return value;
      })
    }),
    jobType: new ColumnDefinition({field: JOB_FIELDS.JOB_TYPE}),
    hoName: new ColumnDefinition({
      field: JOB_FIELDS.GENERAL.HO_NAME,
      parent: JOB_FIELDS.GENERAL.HOMEOWNER.api_name,
      valueGetter: (({data})=> {
        return data?.[jobFields.GENERAL.HOMEOWNER.api_name]?.[JOB_FIELDS.GENERAL.HO_NAME.api_name] || 'N/A';
      })
    }),
    address: new ColumnDefinition({
      field: JOB_FIELDS.GENERAL.JOBSITE_ADDRESS_LINE_1,
      headerName: JOB_FIELDS.GENERAL.ADDRESS.label,
      parent: JOB_FIELDS.GENERAL.ADDRESS.api_name,
      valueGetter: (({data}) => {

        return `${data?.[jobFields.GENERAL.ADDRESS.api_name]?.line1??""} ${data?.[jobFields.GENERAL.ADDRESS.api_name]?.city??""},
               ${data?.[jobFields.GENERAL.ADDRESS.api_name]?.state??""}  ${data?.[jobFields.GENERAL.ADDRESS.api_name]?.zipCode??""}`;
      }),
    }),
    assignedCity: new ColumnDefinition({field: allConstants.CITY_FIELDS.NAME,
      parent: allConstants.PERMIT_MODULE_FIELDS.ASSIGNED_CITY.api_name,
      headerName: 'assigned city',
    }),
    createdTime: new ColumnDefinition({
      field: JOB_FIELDS.CREATE_TIME,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => Utils.formatIsoDateString(data[JOB_FIELDS.CREATE_TIME.api_name],{date: true}))
    }),
    updatedTime: new ColumnDefinition({
      field: JOB_FIELDS.UPDATE_TIME,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => Utils.formatIsoDateString(data[JOB_FIELDS.UPDATE_TIME.api_name],{date: true}))
    }),
    neededByDate: new ColumnDefinition({
      field: JOB_FIELDS.GENERAL.NEED_BY_DATE,
      filter: 'agDateColumnFilter',
      valueGetter: (({data}) => Utils.formatIsoDateString(data[JOB_FIELDS.GENERAL.NEED_BY_DATE.api_name], {date: true}))
    }),
    hersScheduledDate: new ColumnDefinition({field: allConstants.JOB_FIELDS.HERS_SCHEDULED_DATE, filter: 'agDateColumnFilter', valueGetter: (({data}) => Utils.formatIsoDateString(data[allConstants.JOB_FIELDS.HERS_SCHEDULED_DATE.api_name]))}),
    orderedBy: new ColumnDefinition({
      field: {...allConstants.USER_FIELDS.LAST_NAME, api_name: 'fullName'},
      parent: JOB_FIELDS.ORDERED_BY.api_name,
      headerName: JOB_FIELDS.ORDERED_BY.label,
      valueGetter: ({data}) => {
        return `${data[jobFields.ORDERED_BY.api_name]?.firstName} ${data[jobFields.ORDERED_BY.api_name]?.lastName}`;
      },
    }),
    hoPhone: new ColumnDefinition({
      field: allConstants.JOB_FIELDS.GENERAL.HO_PHONE,
      parent: allConstants.JOB_FIELDS.GENERAL.HOMEOWNER.api_name,
      valueGetter: (({data})=> Utils.formatPhoneNumber(data?.[JOB_FIELDS.GENERAL.HOMEOWNER.api_name]?.[JOB_FIELDS.GENERAL.HO_PHONE.api_name]))
    }),
    ...getStatusColumns(jobOptions),
  };
};

export {jobApiTypes, getJobColumns, getStatusColumns};
