const PRODUCT_FIELDS = {
  ID: {api_name: 'id', required: false, label: 'id', display_label: 'id'},
  NAME: {api_name: 'name', required: true, label: 'Product Name', display_label: 'Product Name'},
  CATEGORY: {api_name: 'category', required: true, label: 'Category', display_label: 'Category'},
  CODE: {api_name: 'code', required: true, label: 'Code', display_label: 'Code'},
  DESCRIPTION: {api_name: 'description', required: false, label: 'Description', display_label: 'Description'},
  PRICE: {api_name: 'price', required: true, label: 'Price', display_label: 'Price'},
  LAST_PRICE: {api_name: 'lastPrice', required: false, label: 'Last Price', display_label: 'Last Price'},
  LAST_UPDATE: {api_name: 'lastUpdate', required: false, label: 'Last Update', display_label: 'Last update'},
  LAST_UPDATE_STR: {api_name: 'lastUpdateStr', required: false, label: 'Last Update', display_label: 'Last update'},
  UPDATED_BY: {api_name: 'updatedBy', required: false, label: 'Updated By',display_label: 'Updated By'},
};

export {PRODUCT_FIELDS};
